.page-wrapper{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 15px 40px;
    display: flex;
    gap: 2rem;
}

#body-page, #header-page-wrapper{
    flex: 1;
}

.button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.jsx-parser img{
    max-width: 100%;
}

@media (min-width: 1280px) {
    .page-wrapper{
        max-width: 1280px;
    }
}
@media (max-width: 800px){
    .page-wrapper{
        padding: 0;
        flex-direction: column;
        gap: 0;
    }

    #body-page {
        padding: 0 15px;
    }

    #body-page h1{
        display: none;
    }
}
