.form-action-icons{
    margin:0 8px;
    cursor:pointer;
}

@media (max-width: 800px) {
    .form-action-icons{
        font-size:2em !important;
    }

    .paper h1{
        display: none;
        margin-top: 0;
    }
}

@media (min-width: 800px) {
    .form-action-icons{
        font-size:3em !important;
    }
}

.paper {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form {
    width: 100%;  /*Fix IE 11 issue.*/
    margin-top: 1px;
}
