.signatureCanvas{
    border: 1px solid rgba(0, 0, 0, 0.54);
    width: 100%;
    min-height: 200px;
    border-radius: 4px;
}

@media (max-width: 800px) {
    .signatureCanvasPreview{
        padding: 8px;
        width: 80%;
        text-align: center;
    }
}

@media (min-width: 800px) {
    .signatureCanvasPreview{
        padding: 8px;
        width: 50%;
        text-align: center;
    }
}

#signature_pad {
    width: 100%;
}
