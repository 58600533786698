.ErrorMessage {
    color: black;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    width: 100%;
    transform: translateY(-15px);
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
    margin: 10px 0;
}

.ErrorMessage svg {
    margin-right: 10px;
}
