.loader-container {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    z-index:9999;
}

#loader-logo {
    max-height:100px;
    vertical-align:middle;
}

.spinner-container {
    width: auto;
    height: 75px;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: relative;
    cursor: pointer;
}
