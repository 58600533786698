#map-container > div:first-child{
    height: 100%;
}

#list-map-container {
    padding-left: 0;
    padding-right: 0;
}

@media (max-width: 800px) {
    #list-map-container {
        padding-top: 0;
    }

    #flex-map-container{
        flex-direction: column-reverse;
    }

    #items-container {
        padding-top: 0;
        padding-left: 0;
        margin-top: 8px;
        overflow: inherit;
        border-top: 1px solid rgba(0,0,0,.125);
    }
}

@media (min-width: 800px) {
    #list-map-container {
        padding-top: 15px;
    }

    #items-container {
        padding-left: 0;
        overflow: auto;
    }
}

#list-map-no-result{
    text-align: center;
    margin-top: 70px;
}

#list-map-item-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#list-map-item-container{
    display: flex;
    align-items: center;
    text-align: initial;
    min-height:65px
}

#list-map-item-buttons{
    display: flex;
    justify-content: space-evenly;
    padding: 0
}

#list-map-item-button{
    padding:0 8px;
}

#list-map-info-window {
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer
}
