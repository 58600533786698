.document_display {
    display: grid;
    justify-content: center;
}

.page-controls {
    position: absolute;
    background: white;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 3px 3px 3px 3px #ccc;
    border-radius: 4px;
}

.display_info {
    width: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navigation {
    display: inline-flex;
}

.pdf-container{
    overflow-y: auto;
    box-shadow: 1px 5px 6px grey;
}

.app-bar {
    background-color: rgb(50, 54, 57) !important;
}

.download {
    text-align: right;
}

.download > a {
    color: white;
    text-decoration: none;
}
