.graphvg .right {
    position: absolute;
    top: 50%;
    transform : translate(0%, -50%);
    right: 0;
    width: 100%;
}

.graphvg .left {
    position: absolute;
    top: 50%;
    transform : translate(0%, -50%);
    left: 0;
    width: 100%;
}

.graphvg .bottomleft {
    position: absolute;
    text-align : left;
    bottom: 0;
    left: 0;
    width: 100%;
}

.graphvg .topleft {
    position: absolute;
    transform : translate(0%, -100%);
    text-align : left;
    top: 50%;
    left: 0;
    width: 100%;
}

.graphvg .top {
    position: absolute;
    transform : translate(-50%, -50%);
    text-align : center;
    top: 20px;
    left: 50%;
    width: 100%;
}

.graphvg .bottom {
    position: absolute;
    text-align : center;
    bottom: 0;
    left: 50%;
    transform : translate(-50%, -50%);
    width: 100%;
}

.graphvg .topright {
    position: absolute;
    transform : translate(0%, -100%);
    text-align : right;
    top: 50%;
    right: 0;
    width: 100%;
}

.graphvg .bottomright {
    position: absolute;
    text-align: right;
    bottom: 0;
    right: 0;
    width: 100%;
}

.graphvg .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform : translate(-50%, -50%);
    width: 100%;
}
