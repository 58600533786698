.card-landscape-item{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
    overflow: hidden;
    width: 100%;
    height: 130px;
    cursor: pointer;
    background-color: white;
}

.card-landscape-item-info-wrapper{
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.break-spaces{
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    white-space: break-spaces;
    overflow: hidden;
}

.card-landscape-item-info h4 {
    margin-bottom: 0.5rem;
}

.card-landscape-item-preview{
    width: inherit;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-landscape-item-preview img {
    height: 100%;
    object-fit: cover;
}

.card-landscape-item-icon{
    display: flex;
    align-items: center;
    padding: 8px
}

.card-landscape-item-sub-info{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.card-landscape-item-sub-info span{
    color:#8191a0;
    font-weight:400;
    line-height:1.2rem;
}
