
@media (max-width: 800px) {
    #body-wallet {
        grid-template-columns: 1fr;
        display: grid;
        grid-auto-rows: minmax(50px, auto);
        grid-column-gap: 1em;
    }

    #container-cards-display {
        display: flex;
        flex-direction: row;
        box-shadow: rgba(0, 0, 0, 0.08) 0 3px 5px;
        align-items: center;
        justify-content: space-between;
    }

    #card-logo {
        font-size: 1.5em;
        color: rgba(0, 0, 0, 0.54);
        padding: 0 10px;
        flex: 0 0 10%;
    }

    #card-logo-nfc {
        font-size: 3em;
        color: rgba(0, 0, 0, 0.54);
        padding: 0 10px;
        flex: 0 0 10%;
    }

    #card-delete {
        padding: 0 10px;
        flex: 0 0 10%
    }

    #card-info {
        flex: 0 0 70%;
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media (min-width: 800px) {
    #body-wallet {
        grid-template-columns: repeat(3, 1fr);
        padding: 1.5em 0;
        display: grid;
        grid-auto-rows: minmax(200px, auto);
        grid-column-gap: 1em;
        grid-row-gap: 1em;
    }

    #container-cards-display {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content:space-around;
        box-shadow: rgba(0, 0, 0, 0.08) 0 3px 5px;
        max-width: 400px;
    }

    #card-logo, #card-logo-nfc {
        font-size: 5em;
        color: rgba(0, 0, 0, 0.54);
    }

    .card-logo-nfc {
        font-size: 1.5em;
        color: rgba(0, 0, 0, 0.54);
    }

    #card-delete {
        padding: 0 10%;
    }

    #card-info {
        padding: 0 8px;
    }

    #card-info-title {
        font-weight: 400;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.MuiDialog-paper {
    margin: 8px !important;
}
