* {
    box-sizing: border-box;
}

.AppWrapper {
    max-width: 500px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@keyframes fade {
    from {
        opacity: 0;
        transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
        opacity: 1;
        transform: scale3D(1, 1, 1);
    }
}

.Form {
    animation: fade 200ms ease-out;
}

.FormGroup {
    margin: 10px 0 8px;
    padding: 0;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 white;
    border-radius: 4px;
}

.CardDetail {
    margin-top: 15px;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
}

.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
}

.FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: grey;
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
}

.FormRowInput {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    color: grey;
    background-color: transparent;
    animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
    color: #aab7c4;
    font-size: 16px !important;
}

.CurrentPaymentMethod {
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 white;
    border-radius: 4px;
    padding: 0 !important;
    margin-top: 10px !important;
}

.Dialog {
    min-width: 300px;
}

.PickupCard {
    max-height: 160px;
    overflow-y: auto;
    margin: 0 15px 20px;
    padding: 0;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 white;
}

.PickUpIcon {
    font-size: 2em;
}

.AddCart {
    cursor: pointer;
    margin-bottom: 15px;
}

@keyframes void-animation-out {
    0%,
    to {
        opacity: 1;
    }
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 15px;
}

.CardsButton {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}
