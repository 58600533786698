#announces-category-container{
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 1fr;
    padding: 1rem;
}

#header-announce-mobinaute button{
    margin: 0.25rem
}

#announces-container {
    padding: 1rem;
}

.list-body-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.list-body{
    display: flex;
    flex-direction: column;
    flex: 1 0 50%;
}

.list-body .title{
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    white-space: break-spaces;
    overflow: hidden;
}

.list-announce-info{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex: 1 0 50%;
    padding: 0 1rem;
}

.sub-info{
    color:#8191a0;
    font-weight:400;
    line-height:1.2rem;
}

.wrapper-items-propositions{
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
    flex-wrap: wrap;
    gap: 0.5rem;
}

@media (max-width: 800px) {
    .wrapper-items-propositions{
        flex-direction: column !important;
        align-items: baseline !important;
    }

    .list-announce-info{
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
    }
}
