.withHeader {
    margin-bottom: 15px;
    -moz-box-shadow: 3px 3px 3px 3px #ccc;
    -webkit-box-shadow: 3px 3px 3px 3px #ccc;
    box-shadow: 0 0 1em #ccc;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 65px;
}

.withoutHeader {
    margin-bottom: 15px;
    -moz-box-shadow: 3px 3px 3px 3px #ccc;
    -webkit-box-shadow: 3px 3px 3px 3px #ccc;
    box-shadow: 0 0 1em #ccc;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 75px;
}
