#category-product-container{
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 1fr;
    padding: 1rem;
}

@media (max-width: 800px) {
    #category-product-container{
        background-color: #f1f2f7;
    }
}
